import React from "react";
import { Link } from "gatsby";
import { IoIosArrowForward, IoIosCloudDone } from "react-icons/io";
import {
  IoCheckmarkCircle,
  IoShieldCheckmark,
  IoInformationCircle,
} from "react-icons/io5";
import styled from "styled-components";

import { useModal } from "../components/useModal";

import Image from "../components/image";
import {
  Button,
  Container,
  Wrapper,
  Row,
  Box,
  FlexButtons,
} from "../components/util";
import Layout from "../components/layout";
import Seo from "../components/seo";
import {
  ImageGraphic,
  Modal,
  IframeContainer,
  Breadcrumb,
  CallToAction,
} from "../components/site/";

import AkahuPlusPayHero from "../images/logos/PayHero/PayHeroPlusAkahu.png";
import PayHeroLogo from "../images/logos/PayHero/PayHero_Full.svg";
import AkahuLogo from "../images/logos/Other/Akahu_Logo.png";

const NumberBadge = styled.div`
  background-color: ${(props) => props.theme.colours.grey};
  color: ${(props) => props.theme.colours.dark};
  border-radius: 35px;
  width: 100%;
  height: 100%;
  max-width: 35px;
  max-height: 35px;
  line-height: 35px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  font-weight: 700;
  text-align: center;
  user-select: none;
  margin-top: -7px;
`;

const Akahu = (props) => {
  const [itemModalOpen, setItemModalOpen, toggleModal] = useModal();

  return (
    <Layout product="payhero">
      <Seo
        title="Akahu + PayHero Integration | Easy Employee Payments"
        description="Get payroll done & dusted in minutes by integrating PayHero with Akahu to pay your employees through a secure bank connection."
        pathname={props.location.pathname}
      />
      <Breadcrumb
        parentPage="Integrations"
        parentPageLink="/add-ons"
        currentPage="Akahu"
      />
      <Container>
        <Wrapper>
          <Row stackGap={60} alignCenter>
            <Box size={50} stackGap={30} centerOnMobile>
              <img src={AkahuPlusPayHero} alt="Akahu + PayHero" height="42" />
              <Box stackGap={10}>
                <h1 className="-fontLarge">
                  Connect to your bank and pay employees with ease
                </h1>
                <h4>
                  Integrate PayHero with Akahu to pay your employees through a
                  secure bank connection. Get your payroll done and dusted in
                  minutes.
                </h4>
              </Box>
              <FlexButtons>
                <Button to="/signup" className="primary -lg gtm-cta">
                  Get Started with PayHero
                </Button>
                <Button className="grey -lg gtm-cta" onClick={toggleModal} atag>
                  Book a Sales Call
                </Button>
              </FlexButtons>
            </Box>
            <Box size={50}>
              <ImageGraphic variant="1" />
              <div css={{ padding: "30px" }}>
                <Image
                  filename="AkahuPayHero_Hero.png"
                  alt="MyHR & PayHero Leave Management"
                  rounded
                  addShadow
                />
              </div>
            </Box>
          </Row>
        </Wrapper>
      </Container>
      <Container>
        <Wrapper>
          <Row stackGap={40} className="-textCenter">
            <Box size={33}>
              <h3>
                <IoCheckmarkCircle />
                <br />
                Compliant, considerate payroll
              </h3>
              <p>
                Take care of your team with modern{" "}
                <Link to={process.env.GATSBY_SITE_URL + '/payroll'}>payroll</Link> software designed to
                calculate employee pay, taxes and leave with ease.
              </p>
            </Box>
            <Box size={33}>
              <h3>
                <IoShieldCheckmark />
                <br />
                Secure payment process
              </h3>
              <p>
                Akahu puts you back in control of your data and makes it easier
                than ever to pay your employees and Inland Revenue.
              </p>
            </Box>
            <Box size={33}>
              <h3>
                <IoIosCloudDone />
                <br />
                Better together
              </h3>
              <p>
                Connect reliable payroll software with forward-thinking payment
                technology and immediately elevate your payroll efficiency at no
                extra cost.
              </p>
            </Box>
          </Row>
        </Wrapper>
      </Container>
      <Container bg="lightGrey">
        <Wrapper className="-textCenter">
          <h2>How does the Akahu integration work?</h2>
          <Row stackGap={40}>
            <Box size={33} stackGap={15}>
              <NumberBadge>1</NumberBadge>
              <div>
                <h3>Send</h3>
                <p>
                  Once the integration is confirmed and your Draft Pay is ready
                  to go, use Akahu to instantly pay your employees and Inland
                  Revenue.
                </p>
                <p></p>
              </div>
            </Box>
            <Box size={33} stackGap={15}>
              <NumberBadge>2</NumberBadge>
              <div>
                <h3>Review</h3>
                <p>
                  Successful transactions can be found in the Payments tab, so
                  you never need to worry about losing track of any details
                  again.
                </p>
              </div>
            </Box>
            <Box size={33} stackGap={15}>
              <NumberBadge>3</NumberBadge>
              <div>
                <h3>Control</h3>
                <p>
                  View, update or remove your connected bank accounts at any
                  time. Data held by Akahu can also be deleted.
                </p>
              </div>
            </Box>
          </Row>
          <div className="-center -addShadow" css={{ maxWidth: "1000px" }}>
            <IframeContainer
              src="https://player.vimeo.com/video/707592236"
              title="PayHero + Akahu Integration"
              noBottomRadius
            />
          </div>
          <p
            className="-textCenter -center"
            css={{ color: "#999", maxWidth: " 800px" }}
          >
            <IoInformationCircle css={{ top: "2px", position: "relative" }} />{" "}
            Once you connect with Akahu, you won’t need to connect again. Akahu
            will only collect the business bank account number, and PayHero will
            only collect employee bank account numbers. Please note, Akahu is
            only available for PayHero customers on the Super and Universe plans.
            {/* Once you connect with Akahu, you won’t need to connect again.
            PayHero will only collect employee bank account numbers. For more
            information, visit Akahu's privacy notice{" "}
            <a href="https://www.akahu.nz/privacy-notice" target="_blank">
              here
            </a> 
            .*/}
          </p>
        </Wrapper>
      </Container>
      <Container>
        <Wrapper>
          <Row stackGap={60} alignCenter>
            <Box size={50}>
              <img
                className="-center"
                src={PayHeroLogo}
                alt="PayHero | Online Payroll Made Super Easy"
                height="80"
              />
            </Box>
            <Box size={50} stackGap={30}>
              <div>
                <h2>About PayHero</h2>
                <h4>
                  PayHero is perfect for Kiwi companies that want stress-free
                  and compliant <Link to={process.env.GATSBY_SITE_URL + '/payroll'}>online payroll</Link>.
                  Especially those with a part time or flexible workforce, who
                  face unique challenges tracking employee time accurately and
                  calculating the right holiday entitlements for their staff.
                </h4>
              </div>
              <ul>
                <li>
                  Accurately capture employee hours with online timesheets,
                  employee mobile app or photo clock in and out.
                </li>
                <li>
                  Automatically calculate pay and holiday entitlements from
                  online timesheets and work patterns.
                </li>
                <li>
                  Connect to payday filing and we’ll send your PAYE information
                  to IRD.
                </li>
                <li>
                  Automatically send payroll transactions to Xero and track wage
                  costs with account codes or tracking categories.
                </li>
                <li>Expert phone and email support from our NZ-based team.</li>
              </ul>
              <Link className="link-arrow" to="/signup">
                Get Started for Free <IoIosArrowForward />
              </Link>
            </Box>
          </Row>
          <hr />
          <Row stackGap={60} alignCenter mobileReverse>
            <Box size={50} stackGap={30}>
              <div>
                <h2>About Akahu</h2>
                <h4>
                  Akahu is an open finance platform, focused on New Zealand.
                  Akahu makes it simple to connect your accounts to trusted
                  products like PayHero.
                </h4>
              </div>
              <ul>
                <li>
                  Share your data securely with the best infrastructure,
                  security and data privacy.
                </li>
                <li>
                  Stay in control by choosing how your accounts are connected.
                </li>
                <li>We do not sell or rent your data.</li>
                <li>
                  We always obtain consent before carrying out any instructions
                  regarding your accounts.
                </li>
              </ul>
              <p>
                Find out more about{" "}
                <a href="https://www.akahu.nz/" target="_blank">
                  Akahu
                </a>{" "}
                or manage your accounts at{" "}
                <a href="https://my.akahu.io/login" target="_blank">
                  my.akahu.nz
                </a>
                .
              </p>
            </Box>
            <Box size={50}>
              <img
                className="-center"
                src={AkahuLogo}
                alt="Akahu Integration | PayHero"
                height="200"
              />
            </Box>
          </Row>
        </Wrapper>
      </Container>
      <CallToAction
        title="Connect PayHero & Akahu today"
        description="Simplify your payroll experience with safe, streamlined pay processing"
        buttonText="Get Started with PayHero"
        showSalesButton
      />
      <Modal
        isActive={itemModalOpen}
        embedURL="https://calendly.com/flexitime-sales/sales-consultation"
        embedTitle="Book a Sales Call | FlexiTime Sales"
        handleClose={() => setItemModalOpen(false)}
      />
    </Layout>
  );
};

export default Akahu;
